import React from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import HomeScreenTr from './tr/screens/HomeScreen'

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path='/' component={HomeScreenTr} />

                <Route component={HomeScreenTr} />
            </Switch>
        </Router>
    )
}

export default App