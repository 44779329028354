import React from 'react'
import Meta from '../components/Meta'
import { Container, Row, Col, Image} from 'react-bootstrap'

const HomeScreen = () => {
    return (
        <>
            <Meta 
            title = 'Toplumsal Cinsiyet ve Kültürel Kapsayıcılık Danışmalık Hizmeti'
            description = "Toplumsal Cinsiyet ve Kültürel Kapsayıcılık Danışmalık Hizmeti"
            keywords = 'toplumsal cinsiyet eşitliği, toplumsal cinsiyet, kültürel kapsayıcılık, toplumsal cinsiyet ve kültürel kapsayıcılık, toplumsal cinsiyet danışmanlığı, toplumsal cinsiyet eşitliği eğitimi' />

            <Container>
                <Row>
                    <Col lg={12} xl={12} className='customCol'>
                        <h2>YAPIM AŞAMASINDA</h2>
                        <p>Tel: +90 538 340 15 61<br/>E-mail: tocimofficial@gmail.com</p>
                        <Image className='customImage' src='/tr/tocim-logo.jpg' alt='Toplumsal Cinsiyet ve Kültürel Kapsayıcılık Danışmalık Hizmeti' fluid/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default HomeScreen